<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-modal ref="my-modal" hide-footer title="New Questionnaire" size="lg">
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewQuestionnaireSubmit">
          <b-form-group label="Name">
            <template v-slot:label> Name <span class="text-danger">*</span> </template>
            <validation-provider #default="{ errors }" name="Name" rules="required">
              <b-form-input v-model="newQuestionnaire.title" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description">
            <template v-slot:label>
              Description <span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Description" rules="required">
              <b-form-textarea
                id="textarea"
                v-model="newQuestionnaire.description"
                rows="3"
                max-rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Settings">
            <div class="d-flex">
              <b-form-checkbox
                v-model="newQuestionnaire.res_mandatory"
                :value="true"
                class="custom-control-primary mr-2"
              >
                Response Mandatory
              </b-form-checkbox>
              <b-form-checkbox
                v-model="newQuestionnaire.justification"
                :value="true"
                class="custom-control-primary"
              >
                Justification
              </b-form-checkbox>
            </div>
            <!-- <label for="" class="mt-1">Response Mandatory</label> -->
            <!-- <select name="" id="" class="form-control" v-model="newQuestionnaire.res_mandatory">
              <option value="null">-- Please Select --</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select> -->

            <!-- <label for="" class="mt-1">Justification</label> -->
            <!-- <v-select v-model="newQuestionnaire.justification" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple label="title" :options="options_justification" :reduce="loc => loc.value" /> -->

            <label for="" class="mt-1"> Evidence Required for complaince </label>
            <select
              name=""
              id=""
              class="form-control"
              v-model="newQuestionnaire.evidence_rqd"
              style="width: 308px"
            >
              <option value="null">-- Please Select --</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </b-form-group>

          <b-button type="submit" variant="primary" class="mt-3 mr-1" :disabled="invalid">
            <feather-icon icon="CheckIcon" class="mr-50" />
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
    </b-sidebar>
    <b-tabs content-class="mt-2" align="start">
      <b-tab title="My Questionnaires">
        <div class="card">
          <div class="w-100 row py-1">
            <div class="app-fixed-search col-sm-6">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    v-if="
                      importedQuestionnairesFilters.search == null ||
                      importedQuestionnairesFilters.search == ''
                    "
                    icon="SearchIcon"
                    class="text-muted"
                  />
                  <feather-icon
                    @click="
                      () => {
                        importedQuestionnairesFilters.search = null;
                      }
                    "
                    v-else
                    icon="XIcon"
                    class="text-danger cursor-pointer"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="importedQuestionnairesFilters.search"
                  placeholder="Search..."
                  debounce="500"
                  size="md"
                />
              </b-input-group>
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-end">
              <b-button size="sm" variant="success" @click="addNewQuestionnaire"
                ><feather-icon icon="PlusIcon" class="mr-50" />New Questionnaire</b-button
              >
            </div>
          </div>
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col" style="width: 100px;">#</th>
                <th role="columnheader" scope="col" style="width: 360px;">Name</th>
                <th role="columnheader" scope="col" style="width: 360px;">Description</th>
                <th role="columnheader" scope="col" style="width: 100px;">Created At</th>
                <!-- <th role="columnheader" scope="col">Actions</th> -->
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template
                v-if="
                  importedQuestionnaires.data &&
                  importedQuestionnaires.data != null &&
                  importedQuestionnaires.data.length > 0
                "
              >
                <tr
                  v-for="(item, index) in importedQuestionnaires.data"
                  :key="item._id"
                  role="row"
                  class="cursor-pointer"
                  @click="handleMyQuestionnaireQuestionClick(item)"
                >
                  <th>
                    {{
                      (importedQuestionnairesFilters.page - 1) *
                        importedQuestionnairesFilters.limit +
                      index +
                      1
                    }}
                  </th>
                  <td role="cell" class="max-width: 25vw;">
                    <span class="font-weight-bold">{{ item.title }}</span
                    ><br />
                    <b-badge v-if="item.is_customized" variant="success"
                      >Customized</b-badge
                    >
                  </td>

                  <td role="cell" style="max-width: 25vw">
                    {{ item.description }} <br />
                  </td>
                  <td aria-colindex="2" role="cell">
                    {{ item.created_at | moment }}
                  </td>
                  <!-- <td aria-colindex="2" role="cell">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 150px"
                    >
                      <b-button
                        @click.stop="handleRemoveAction(item._id)"
                        variant="danger"
                        size="sm"
                        ><feather-icon icon="XIcon" class="mr-75" /><span
                          class="align-middle"
                          >Remove</span
                        ></b-button
                      >
                    </div>
                  </td> -->
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Questionnaires Empty">
                  <template #content>
                    <p class="text-center">
                      It seems there are no questionnaires at the moment.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
          <div class="d-flex align-items-center justify-content-center w-100 py-2">
            <b-pagination
              v-model="importedQuestionnairesFilters.page"
              :per-page="importedQuestionnaires.pagination.perPage"
              :total-rows="importedQuestionnaires.pagination.totalRows"
              aria-controls="my-table2"
            ></b-pagination>
          </div>
        </div>
      </b-tab>
      <!-- <b-tab title="Questionnaire Templates">
        <div class="card">
          <div class="card-body">
            <div class="pb-25 pt-0 w-100 row">
              <div class="app-fixed-search col-md-8 col-sm-12">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon
                      v-if="filters.search == null || filters.search == ''"
                      icon="SearchIcon"
                      class="text-muted"
                    />
                    <feather-icon
                      @click="
                        () => {
                          filters.search = null;
                        }
                      "
                      v-else
                      icon="XIcon"
                      class="text-danger cursor-pointer"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filters.search"
                    placeholder="Search..."
                    debounce="500"
                    size="md"
                  />
                </b-input-group>
              </div>
            </div>
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">#</th>
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Created At</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="(item, index) in questionnaire"
                  :key="item._id"
                  role="row"
                  class="cursor-pointer"
                  @click="hanldeQuestionnaireClick(item)"
                >
                  <td aria-colindex="2" role="cell">
                    {{ (filters.page - 1) * filters.limit + index + 1 }}
                  </td>
                  <td aria-colindex="2" role="cell">
                    <div class="d-flex justify-content-start">
                      <p class="text-left text-primary font-weight-bold mb-0">
                        {{ item.title }} <br />
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell">
                    <div class="d-flex justify-content-start">
                      <p class="text-left font-italic font-weight-bold mb-0">
                        {{ item.description }}
                      </p>
                    </div>
                  </td>
                  <td aria-colindex="2" role="cell" style="width: 200px">
                    <div class="d-flex justify-content-start" style="width: 200px">
                      <p class="text-left font-weight-bold mb-0">
                        {{ item.created_at | moment }}
                      </p>
                    </div>
                  </td>
                  <td aria-colindex="2" role="cell">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 150px"
                    >
                      <b-button
                        @click.stop="handleImportAction(item._id)"
                        variant="primary"
                        size="sm"
                        ><feather-icon icon="UploadIcon" class="mr-75" /><span
                          class="align-middle"
                          >Import</span
                        ></b-button
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex align-items-center justify-content-center w-100 py-2">
              <b-pagination
                v-model="filters.page"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-tab> -->
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BSidebar,
  BPagination,
  BAvatar,
  BTabs,
  BTab,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import AddVendorUserForm from "../components/AddVendorUserForm.vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import vSelect from "vue-select";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BOverlay,
    AddVendorUserForm,
    BPagination,
    BAvatar,
    BTabs,
    BTab,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    EmptyTableSection,
    BBadge,
    BFormCheckbox,
    vSelect,
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  mixins: [ResponseMixins, QuestionnaireMixins, TaskMixins],
  data() {
    return {
      newQuestionnaire: {
        title: null,
        description: null,
        settings: {
          evidence_required: null,
          response_mandatory: null,
          justification: [],
        },
      },
      showOverlay: false,
      vendorList: null,
      questionnaire: null,
      importedQuestionnaires: {
        data: null,
        pagination: {
          lastPage: 1,
          totalRows: 1,
          perPage: 10,
        },
      },
      importedQuestionnairesFilters: {
        page: 1,
        limit: 10,
        search: null,
      },
      pagination: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
      },
      filters: {
        page: 1,
        limit: 10,
        search: null,
      },
      evidence_rqd: null,
      res_mandatory: null,
      justification: [],
      options_justification: [
        { title: "Yes", value: "yes" },
        { title: "No", value: "no" },
        { title: "Both", value: "both" },
        { title: "Not Applicable", value: "notapplicable" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    filters: {
      handler: function (newValue) {
        this.getNonImportedQuestionnaireAndSetData(newValue);
      },
      deep: true,
    },
    importedQuestionnairesFilters: {
      handler: function (newValue) {
        this.getImportedQuestionnairesAndSetData(newValue);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getNonImportedQuestionnaireAndSetData(this.filters);
      this.getImportedQuestionnairesAndSetData(this.importedQuestionnairesFilters);
    },
    addNewQuestionnaire() {
      this.newQuestionnaire = {
        title: null,
        description: null,
      };
      this.showModal();
    },
    handleNewQuestionnaireSubmit() {
      this.showOverlay = true;
      this.createCustomQuestionnaire(this.newQuestionnaire)
        .then((res) => {
          this.handleResponse(res);
          this.load();
          this.hideModal();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },

    handleMyQuestionnaireQuestionClick(questionnaire) {
      this.$router.push(`/imported-questionnaires/${questionnaire._id}/details`);
    },

    handleImportAction(id) {
      this.importNonImportedQuestionnaire(id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    handleRemoveAction(id) {
      this.removeImportedQuestionnaire(id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    hanldeQuestionnaireClick(questionnaire) {
      this.$router.push(`/questionnaires/${questionnaire._id}/details`);
    },

    getImportedQuestionnairesAndSetData(filters) {
      this.getImportedQuestionnaires(filters)
        .then((res) => {
          this.importedQuestionnaires.data = res.data.data.data;
          this.importedQuestionnaires.pagination.lastPage = res.data.data.last_page;
          this.importedQuestionnaires.pagination.totalRows = res.data.data.total;
          this.importedQuestionnairesFilters.page = res.data.data.current_page;
          this.importedQuestionnairesFilters.limit = res.data.data.per_page;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getNonImportedQuestionnaireAndSetData(filters) {
      this.showOverlay = true;
      this.getNonImportedQuestionnaire(filters)
        .then((res) => {
          this.questionnaire = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.totalRows = res.data.data.total;
          this.filters.page = res.data.data.current_page;
          this.filters.limit = res.data.data.per_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
